import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import AOS from 'aos'
import 'aos/dist/aos.css'

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
      document.title = to.meta.title
    }
    document.documentElement.scrollTop = 0
    next()
  })

createApp(App).use(store).use(router).use(AOS).mount("#app");
